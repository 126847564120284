import React from 'react';

import Layout from '../../components/layout';
import Snippet from '../../components/snippet';
import PageTitle from '../../components/pagetitle';
import ExDocLink from '../../components/exdoclink';
import Warning from '../../components/warning';
import SEO from '../../components/seo';


const IndexPage = () => (
  <Layout>
    <SEO title="Automatically add a site map to Google and Bing" />
    <PageTitle>Automatically add a site map to Google and Bing</PageTitle>

    <Snippet title="Submit your sitemap to Bing.com">
        <p>
          http://www.bing.com/ping?sitemap=https%3A%2F%2Fyour-site.com/sitemap.xml
        </p>
        <p>
        # This is how our site map looks like:<br />
        # https://ipax.dev/sitemap.xml
        </p>
    </Snippet>

    <Snippet title="Submit your sitemap to Google.com">
        <p>
          http://www.google.com/ping?sitemap=https://your-site.com/sitemap.xml
        </p>
    </Snippet>
    <h4>Check if your website have been indexed</h4>
    <p>
      Search for: <strong>site:your-site.com</strong>
    </p>
    <p>
      Will work on Google, Bing, Yahoo and probably many more.
    </p>

    <h3>More about this topic from other sources</h3>
    <p>
      <a href="https://www.impactbnd.com/blog/how-long-does-it-take-google-to-index-a-new-page">
        How to Get Google to Index Your New Website in 2019 (& 11 Ways to Do it Instantly)
      </a>
    </p>
  </Layout>
)

export default IndexPage